<template>
    <div class="readinglist" :class="background == Color?'active':''">
        <p @click="preId">上一章</p>
        <p @click="directoryClick">目录</p>
        <p @click="nextId">下一章</p>
    </div>
</template>

<script>
    export default {
        name: "ReadingList",
        props: {
            background: String,
            Color: String
        },
        methods: {
            directoryClick() {
                this.$emit('directoryClick',)
            },
            preId() {
                this.$emit('preId')
            },
            nextId() {
                this.$emit('nextId')
            }
        }
    }
</script>

<style scoped>


    .readinglist {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 40px 0;
    }

    .readinglist > p {
        border: 1px solid #fff;
        padding: 20px 50px;
        border-radius: 80px;
        font-size: 30px;
        color: #303133;
    }

    .active > p {
        color: #fff !important;
    }
</style>
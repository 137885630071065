import { render, staticRenderFns } from "./Left.vue?vue&type=template&id=b7a0483a&scoped=true"
import script from "./Left.vue?vue&type=script&lang=js"
export * from "./Left.vue?vue&type=script&lang=js"
import style0 from "./Left.vue?vue&type=style&index=0&id=b7a0483a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7a0483a",
  null
  
)

export default component.exports
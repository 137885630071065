<template>
    <div>
        <div class="pay_top">
            <p class="pay_top_title">订购</p>
        </div>
        <div class="pay_center">
            <p><img :src="chapterList.coverpic" alt="" class="book_img"></p>
            <div class="pay_center_right">
                <div class="pay_center_list active">
                    <p class="title">{{chapterList.title}}{{chapterList.title}}</p>
                    <p class="free">
                        {{readList.coin}}<img src="../../assets/images/bookbean_icon.png" alt="" class="bookbean_icon"/>/章
                    </p>
                </div>
                <div class="pay_center_list">
                    <p class="subtitle">{{readList.title}}</p>
                    <p class="right_subtitle" @click="payList">购买本章</p>
                </div>
            </div>
        </div>
        <p class="vip_free">
            <!--            <img src="../../assets/images/vip_free.png" alt="">-->
            会员<span>免费</span>看全部书籍
        </p>
        <div class="pay_list">
            <p class="pay_button" @click="buttonClick">
                开通会员
            </p>
            <p class="pay_button" @click="topupClick">
                充值书豆
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            chapterList: Object,
            readList: Object,
        },
        name: "Pay",
        data() {
            return {}
        },
        methods: {
            buttonClick() {
                this.$emit('buttonClick')

            },
            topupClick() {
                this.$emit('topupClick')

            },
            payList() {
                this.$emit('payList')
            },
        }
    }
</script>

<style scoped>

    .free {
        color: #fff;
        font-size: 20px;
        background: #4d77fd7d;
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pay_list {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .pay_list > p {
        width: 50%;
    }

    .pay_button {
        background: linear-gradient(135deg, rgba(250, 227, 181, 1) 0%, rgba(246, 211, 151, 1) 100%);
        border-radius: 44px;
        padding: 28px;
        text-align: center;
        font-size: 32px;
        color: #564523;
        margin: 40px;
    }

    .vip_free_text {
        color: #909399;
        font-size: 28px;
    }

    .bookbean_icon {
        width: 15px;
        height: 15px;
        display: inline-block;
        margin: 0 2px;
    }

    .vip_free_text > span {
        text-decoration: line-through;
    }

    .vip_free {
        text-align: center;
        font-size: 38px;
        font-style: oblique;
        font-weight: bold;
        padding: 30px 0;
    }

    .vip_free > span {
        color: #FCAD0F;
    }

    .vip_free > img {
        width: 251px;
        height: 35px;
        display: inline-block;
        margin-bottom: 40px;
    }

    .active {
        margin-bottom: 40px;
    }

    .pay_center_right {
        width: 100%;
    }

    .right_subtitle {
        color: #FCAD0F;
        font-size: 30px;
    }

    .right_title {
        font-size: 32px;
        color: #606266;
    }

    .subtitle {
        font-size: 30px;
        color: #606266;
        width: 350px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .title {
        font-size: 32px;
        color: #0A1C33;
        width: 350px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .pay_center_list {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .pay_center {
        padding: 40px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .book_img {
        width: 100px;
        height: 140px;
        display: inline-block;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
        border-radius: 10px;
        margin-right: 20px;
    }

    .pay_top {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 40px;
        font-size: 36px;
        color: #303133;
        border-bottom: 1px solid #ECECEC;
    }
</style>
<template>
    <div>
        <div class="mask-bottom" :class="background == Color?'active':''">
            <p @click="preId">上一章</p>
            <p @click="$emit('leftClick')">目录</p>
            <p @click="nextId">下一章</p>
        </div>
        <div class="background">
            <p class="background-text" :class="background == Color?'active':''">背景</p>
            <div class="background-right">
                <template v-for="item in backgroundList">
                    <p :style="{background:item}" @click="backgroundClick(item)"></p>
                </template>
            </div>
        </div>
        <div class="background">
            <p class="background-text" :class="background == Color?'active':''">字体</p>
            <div class="background-right">
                <van-slider v-model="value" @change="onChange" active-color="#4D77FD" min="18" max="36">
                    <template #button>
                        <div class="custom-button">
                            {{ value }}
                        </div>
                    </template>
                </van-slider>
            </div>
        </div>
        <div class="background" v-if="user_infoList.is_vip != '1'">
            <p class="pay_title" :class="background == Color?'active':''">自动购买付费章节</p>
            <van-switch v-model="checked" size="20px" :inactive-color="background"
                        active-color="#4D77FD" @input="switchClick(checked)"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Button",
        props: {
            value: Number,
            background: String,
            Color: String,
            checked: Boolean,
            user_infoList: Object,
        },
        data() {
            return {
                backgroundList: ['#E7E7E7', '#DED9C5', '#D4E0C6', '#CCD8E4', '#F9D4D4', '#543131'],
            }
        },
        methods: {
            switchClick(checkbox) {
                this.$emit('switchClick', checkbox)
            },
            onChange(value) {
                this.$toast('当前字号：' + value);
                localStorage.setItem('fontSize', value)
                this.$emit('fontSizeClick', value)
            },
            backgroundClick(item) {
                localStorage.setItem('background', item)
                this.$emit('backgroundClick', item)
            },
            preId() {
                this.$emit('preId')
            },
            nextId() {
                this.$emit('nextId')
            }
        }
    }
</script>

<style scoped>
    .pay_title {
        font-size: 28px;
    }

    .active {
        color: #fff !important;
    }

    .custom-button {
        color: #fff;
        font-size: 20px;
        text-align: center;
        background-color: #4D77FD;
        border-radius: 20px;
        padding: 5px 10px;
    }

    .background-text {
        width: 120px;
        color: #303133;
        font-size: 30px;
    }

    .background {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 50px 0 0 0;
    }

    .background-right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .background-right > p {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        border: 1px solid #606266;
    }

    .mask-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #303133;
        font-size: 30px;
    }
</style>
<template>
    <div>
        <div class="left-top-list" :style="{background: background}">
            <div class="left-top">
                <div class="left-left">
                    <p class="points_relative">
                        <img :src="chapterList.coverpic" alt="" class="book-img">
                    </p>
                    <div>
                        <p class="title" :class="background == Color?'active':''">{{chapterList.title}}</p>
                        <p class="left_icon" :class="background == Color?'active':''">
                            <img src="../../assets/images/left_icon.png" alt="">
                            <span>{{chapterList.author}}</span>
                        </p>
                    </div>
                </div>
                <p>
                    <van-icon name="arrow" color="#fff" size="15" v-if="background == Color"/>
                    <van-icon name="arrow" color="#606266" size="15" v-else/>
                </p>
            </div>
            <div class="reading-center" :class="background == Color?'active':''">
                <p class="reading-center-left">{{chapterList.iswz == 1 ?'连载中':'已完结'}} 共{{chapterList.allchapter}}章</p>
                <p class="reading-center-right" :class="background == Color?'active':''" @click="TextClick">
                    {{text_show?'正序':'倒序'}}</p>
            </div>
        </div>
        <div class="div1" ref="scroll" :style="{background: background}">
            <template v-for="item in chapterList.chapters">
                <div class="list" @click="chapsClick(item)">
                    <p class="list-title"
                       :class="[{'active-text':chaps == item.chaps},{'active':background == Color}]">
                        {{item.title}}</p>
                    <template v-if="user_infoList.is_vip !='1'">
                        <div v-if="item.is_pay == '0' && item.coin != '0'">
                            <p class="free">
                                <span class="coin-text">{{item.coin}}</span>
                                <img src="../../assets/images/bookbean_icon.png" alt="" class="bookbean_icon"/>
                            </p>
                        </div>
                        <div v-else>
                            <p class="free">免费</p>
                        </div>
                    </template>
                    <template v-else>
                        <p class="vip">
                            <img src="../../assets/images/vip_tit_img.png" alt="" class="vip_tit_img">
                        </p>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Left",
        props: {
            id: String,
            chapterList: Object,
            end: Object,
            chaps: Number,
            background: String,
            Color: String,
            user_infoList: Object,
        },
        data() {
            return {
                text_show: false,
            }
        },
        methods: {
            TextClick() {
                this.text_show = !this.text_show
                this.$refs.scroll.scrollTop = 0;
                this.chapterList.chapters.reverse()
            },
            chapsClick(item) {
              sessionStorage.removeItem("chaps");
              sessionStorage.removeItem("anime_id");
              this.$emit("chapsClick", item)
            }
        }
    }
</script>

<style scoped>
    .vip {
        background: #000;
        padding: 0 5px;
        border-radius: 5px;
    }

    .vip_tit_img {
        width: 45px;
        height: 20px;
    }

    .bookbean_icon {
        width: 19px;
        height: 20px;
        display: inline-block;
        margin: 0 0 0 10px;
    }

    .active {
        color: #fff !important;
    }

    .free {
        color: #fff;
        font-size: 20px;
        background: #4d77fd7d;
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .coin-text {
        font-size: 20px;
        color: #fff;
    }

    .div1 {
        position: absolute;
        top: 298px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        z-index: -1;
    }

    .active-text {
        color: #4D77FD !important;
    }

    .list {
        border-top: 1px solid #BFC2CC;
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .list-title {
        color: #303133;
        font-size: 30px;
        width: 400px;
        text-align: justify;
        line-height: 40px;
    }

    .suo {
        width: 28px;
        height: 30px;
        display: inline-block;
    }

    .left-top-list {
        position: sticky;
        top: 0;
        padding: 60px 40px 40px;
    }

    .reading-center-right {
        color: #303133;
    }

    .reading-center-left {
        width: 350px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .reading-center {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #606266;
        font-size: 24px;
        margin-top: 30px;
    }

    .title {
        color: #303133;
        font-size: 36px;
        margin-bottom: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 300px;

    }

    .left-left {
        display: flex;
        align-items: center;
    }

    .book-img {
        border-radius: 10px;
        width: 100px;
        height: 140px;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
        margin-right: 20px;
        display: inline-block;
    }

    .left-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .left_icon {
        color: #606266;
        font-size: 24px;
        display: flex;
        align-items: center;
    }

    .left_icon > img {
        width: 20px;
        height: 23px;
        margin-right: 10px;
    }

    .left_icon > span {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>